<template>
  <div>
    <!-- {{goodsToAdd}} -->
    <v-form ref="addGoodsForm">
      <v-container fluid style="height: 270px" v-if="goodsToAdd">
        <v-row align="center">
          <v-col cols="4">
            <v-text-field v-model="goodsToAdd.code" :label="tLabel('Code')" clearable @input="(val) => (goodsToAdd.code = val ? val.toUpperCase() : null)"/>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="goodsToAdd.description" :label="tLabel('Description')" clearable />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="3">
            <v-select
              :label="tLabelRequired('Type')"
              v-model="goodsToAdd.goodType"
              :items="goodsTypeCodes"
              item-value="goodsTypeCode"
              item-text="goodsTypeCode"
              :rules="[rules.required]"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabelRequired('Colli')" v-model.number="goodsToAdd.quantity" :rules="[rules.numeric, rules.required]" clearable />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('Weight')" v-model.number="goodsToAdd.weight" :rules="[rules.numeric]" clearable />
          </v-col>
          <v-col cols="3">
            <v-select v-model="goodsToAdd.cycle" :items="cyclesList" :label="tLabel('Cycle')" />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="text-right">
            <v-btn color="primary" @click="addGoods" :disabled="addDisabled"><v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Aggiungi") }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-container fluid>
      <v-row align="center" class="mt-0 pt-0">
        <v-col cols="12">
          <v-card color="grey lighten-5">
            <v-card-text>
              <v-row align="center" class="mt-0 pt-0">
                <v-col cols="12">
                  <span class="text-h6">{{ tLabel("Merce identificata") }}:</span>
                </v-col>
              </v-row>

              <v-row align="center" class="mt-0 pt-0">
                <v-col cols="12">
                  <v-simple-table fixed-header dense class="grey lighten-5">
                    <template v-slot:default>
                      <thead class="grey lighten-5">
                        <tr>
                          <!-- <th class="text-left">visit id</th> -->
                          <th style="width: 40px"></th>
                          <th class="text-left">{{ tLabel("Code") }}</th>
                          <th class="text-left">{{ tLabel("Descr.") }}</th>
                          <th class="text-left">{{ tLabel("Type") }}</th>
                          <th class="text-left">{{ tLabel("Colli") }}</th>
                          <th class="text-left">{{ tLabel("Weight") }}</th>
                          <th class="text-left">{{ tLabel("Cycle") }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="goodsToCreate && goodsToCreate !== null && goodsToCreate.length > 0">
                        <tr v-for="gts in goodsToCreate" :key="gts.tmpId">
                          <td>
                            <v-icon @click="removeGoods(gts)" :title="tLabel('Rimuovi merce selezionata.')" small>mdi-close</v-icon>
                          </td>
                          <td>{{ gts.code }}</td>
                          <td>{{ gts.description }}</td>
                          <td>{{ gts.goodType }}</td>
                          <td>{{ gts.quantity }}</td>
                          <td>{{ gts.weight }}</td>
                          <td>{{ gts.cycle }}</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="6">{{ tLabel("Nessuna merce.") }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatsMixins from "../../mixins/FormatsMixins";
import YardEnumMixin from "../../mixins/yard/EnumMixin";

export default {
  name: "GoodsCreationPerOperationPanel",
  data() {
    return {
      goodsToAdd: null,
      goodsId: 0,
      goodsTypeCodes: [],
      defaultCycle: null,
    };
  },
  props: {
    goodsToCreate: {
      type: Array,
      required: true,
    },
    position: {
      type: String,
      required: false,
      default: null,
    },
  },
  mixins: [FormatsMixins, YardEnumMixin],
  async mounted() {
    if (this.goodsToCreate !== null && this.goodsToCreate.length > 0) {
      this.goodsToCreate.forEach((goo) => {
        goo.tmpId = ++this.goodsId;
      });
    }
    this.goodsTypeCodes = await this.$api.yardData.goodsTypeCodes();
    const parametersList = await this.$api.registryData.getParameters();
    const parametersMap=Object.assign({}, ...parametersList.map(s => ({[s.key]: s.value})));
    this.defaultCycle=parametersMap["YARD.GOODS.STRIPPING.CYCLE"] || 'I';
    this.goodsToAdd={
      cycle: this.defaultCycle
    }
  },
  //   emits: ["goodsCreatedPerOperation"],
  computed: {
    addDisabled() {
      return (
        Object.keys(this.goodsToAdd).length === 0 ||
        ((!this.goodsToAdd.code || this.goodsToAdd.code === null || this.goodsToAdd.code === "") &&
          (!this.goodsToAdd.goodType || this.goodsToAdd.goodType === null || this.goodsToAdd.goodType === "") &&
          (!this.goodsToAdd.description || this.goodsToAdd.description === null || this.goodsToAdd.description === "") &&
          (!this.goodsToAdd.weight || this.goodsToAdd.weight === null || this.goodsToAdd.weight === "") &&
          (!this.goodsToAdd.quantity || this.goodsToAdd.quantity === null || this.goodsToAdd.quantity === ""))
      );
    },
  },
  methods: {
    addGoods() {
      const valid = this.$refs.addGoodsForm.validate();
      if (!valid) {
        return;
      }
      this.goodsToAdd.tmpId = ++this.goodsId;
      this.goodsToAdd.position = this.position;
      this.goodsToCreate.push(this.goodsToAdd);
      this.goodsToAdd={
        cycle: this.defaultCycle
      };
      this.$refs.addGoodsForm.resetValidation();
      // this.$emit("goodsCreatedPerOperation", this.goodsToCreate);
    },
    removeGoods(goo) {
      var index = this.goodsToCreate.indexOf(goo);
      if (index > -1) {
        this.goodsToCreate.splice(index, 1);
      }
    },
  },
};
</script>

<style></style>
