<template>
  <div>
    <v-hover v-slot="{ hover }" open-delay="160" close-delay="160">
      <v-card
        rounded="0"
        :elevation="selectable && hover ? 6 : 2"
        :style="selectable && selected === true ? 'border: 3px solid #FFEB3B !important;' : null"
        :color="selectable && hover ? color + ' lighten-2' : color + ' lighten-3'"
        v-on="selectable ? { click: onClickCard } : {}"
      >
        <v-card-text class="my-0 py-0">
          <v-row align="center" class="my-0 py-0">
            <v-col cols="12" align-self="start">
              <v-container fluid class="ma-0 pa-0">
                <v-row class="mb-0 pb-0">
                  <v-col cols="2" class="mb-0 pb-0">
                    <v-icon
                      :title="tLabel('Rimuovi dall\'operazione di stuffing.')"
                      v-if="removable === true && selected === false"
                      @click.stop="onClickDelete"
                      :color="removeGoodsColor"
                      @mouseover="removeGoodsColor = 'red darken-3'"
                      @mouseleave="removeGoodsColor = null"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                  <v-col cols="10" class="text-right mb-0 pb-0">
                    <v-tooltip bottom v-if="goodsLabel.length > 11" color="white">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" :class="`text-subtitle-1 text-uppercase font-weight-medium ${whiteText ? 'white--text' : null}`">{{ goodsLabelTrimmed }}</span>
                      </template>
                      <span>{{ goodsLabel }}</span>
                    </v-tooltip>  
                    <span v-else :class="`text-subtitle-1 text-uppercase font-weight-medium ${whiteText ? 'white--text' : null}`">{{ goodsLabel }}</span>                 
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                  <v-col cols="12" :class="`text-subtitle-2 text-uppercase text-right mt-0 pt-0 ${whiteText ? 'white--text' : null}`">
                    {{ goodsQtyWeightLabel !== "" ? goodsQtyWeightLabel : "&nbsp;" }}
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="12" align-self="center" />
            <v-col cols="12" align-self="end">
              <v-container fluid class="ma-0 pa-0">
                <v-row align="center" class="mb-0 pb-0" v-if="timeArrivalVisible === true">
                  <v-col v-if="goods.ata && goods.ata !== null" cols="12" class="text-subtitle-2 text-uppercase text-left mb-0 pb-0">
                    <v-icon color="secondary">mdi-warehouse</v-icon>
                  </v-col>
                  <v-col v-else cols="12" class="text-subtitle-2 text-uppercase text-left mb-0 pb-0">
                    <v-icon color="secondary">mdi-clock-fast</v-icon>
                  </v-col>
                </v-row>
                <v-row align="center" class="mb-0 pb-0" v-else>
                  <v-col cols="12" class="text-subtitle-2 text-uppercase text-left mb-0 pb-0"> &nbsp; </v-col>
                </v-row>
                <v-row class="mt-0 pt-0" v-if="positionVisible">
                  <v-col cols="12" :class="`text-subtitle-2 text-uppercase text-left mt-0 pt-0 ${whiteText ? 'white--text' : null}`">
                    {{ goods.position && goods.position !== null ? goods.position : "N/A" }}
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0" v-else>
                  <v-col cols="12" class="text-subtitle-2 text-uppercase text-left mt-0 pt-0"> &nbsp; </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: "OrderOpGoodsOperativeCard",
  data() {
    return {
      selected: false,
      removeGoodsColor: null,
    };
  },
  props: {
    goods: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "grey",
    },
    whiteText: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeArrivalVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    positionVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["goodsSelected", "goodsRemoving"],
  computed: {
    goodsLabel() {
      if (this.goods.description && this.goods.description !== null && this.goods.description !== "") {
        return this.goods.description;
      } else {
        return this.goods.code;
      }
    },
    goodsLabelTrimmed() {
      return this.trimGoodsLabel(this.goodsLabel);     
    },
    goodsQtyWeightLabel() {
      var res = "";
      if (this.goods.quantity && this.goods.quantity !== null) {
        res += this.goods.quantity;
        if (this.goods.weight && this.goods.weight !== null) {
          res += " | ";
        }
      }
      if (this.goods.weight && this.goods.weight !== null) {
        res += (Math.round(this.goods.weight * 100) / 100).toFixed(2).replace(".", ",") + " Kg";
      }
      return res;
    },
  },
  methods: {
    trimGoodsLabel(label) {
      return label && label !== null && label !== "" && label.length > 11 ? label.substring(0, 9) + ".." : label;
    },
    onClickCard() {
      this.selected = !this.selected;
      this.$emit("goodsSelected", { visitId: this.goods.visitId, handlingId: this.goods.handlingId, selected: this.selected });
    },
    onClickDelete() {
      this.$emit("goodsRemoving", this.goods);
    },
  },
};
</script>

<style scoped></style>
