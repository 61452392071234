<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6">
        <v-text-field v-model="unitSearchFilter" :label="tLabel('Number')" prepend-inner-icon="mdi-magnify" dense clearable @input="(val) => (unitSearchFilter = val ? val.toUpperCase() : null)"/>
      </v-col>
    </v-row>
    <v-row align="center" class="mt-0 pt-0">
      <v-col cols="12">
        <v-simple-table height="210" fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ tLabel("Number") }}</th>
                <th class="text-left">{{ tLabel("Type") }}</th>
                <th class="text-left">{{ tLabel("Weight") }}</th>
                <th class="text-left">{{ tLabel("Position") }}</th>
                <th class="text-left">{{ tLabel("Date In") }}</th>
              </tr>
            </thead>
            <tbody v-if="unitsFiltered !== null && unitsFiltered.length > 0">
              <tr v-for="unit in unitsFiltered" :key="unit.visitId" @click="onUnitClick(unit)" class="pointer">
                <td>{{ unit.number }}</td>
                <td>{{ unit.type }}</td>
                <td>{{ unit.weight }}</td>
                <td>{{ unit.position && unit.position !== null ? unit.position : "N/A" }}</td>
                <td>
                  <v-icon class="pr-1" v-if="unit.ata && unit.ata !== null">mdi-warehouse</v-icon>
                  <v-icon class="pr-1" v-else>mdi-clock-fast</v-icon>
                  {{ unit.ata && unit.ata !== null ? unit.ata : unit.eta | formatDateTimeFromDate }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">{{ tLabel("Nessuna unit disponibile.") }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row align="center" class="mt-0 pt-0">
      <v-col cols="12">
        <v-card color="grey lighten-5" rounded="0">
          <v-card-text>
            <v-row align="center" class="mt-0 pt-0">
              <v-col cols="12">
                <span class="text-h6">{{ tLabel("Unit selezionata") }}:</span>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-0 pt-0">
              <v-col cols="12">
                <v-simple-table fixed-header dense class="grey lighten-5">
                  <template v-slot:default>
                    <thead class="grey lighten-5">
                      <tr>
                        <th style="width: 40px"></th>
                        <th class="text-left">{{ tLabel("Number") }}</th>
                        <th class="text-left">{{ tLabel("Type") }}</th>
                        <th class="text-left">{{ tLabel("Weight") }}</th>
                        <th class="text-left">{{ tLabel("Position") }}</th>
                        <th class="text-left">{{ tLabel("Date In") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="unitsToAdd && unitsToAdd !== null && unitsToAdd.length > 0">
                      <tr v-for="uta in unitsToAdd" :key="uta.visitId">
                        <td>
                          <v-icon @click="removeFromUnitsToAdd(uta)" :title="tLabel('Rimuovi unit selezionata.')" small>mdi-close</v-icon>
                        </td>
                        <td>{{ uta.number }}</td>
                        <td>{{ uta.type }}</td>
                        <td>{{ uta.weight }}</td>
                        <td>{{ uta.position && uta.position !== null ? uta.position : "N/A" }}</td>
                        <td>
                          <v-icon class="pr-1" v-if="uta.ata && uta.ata !== null">mdi-warehouse</v-icon>
                          <v-icon class="pr-1" v-else>mdi-clock-fast</v-icon>
                          {{ uta.ata && uta.ata !== null ? uta.ata : uta.eta | formatDateTimeFromDate }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="6">{{ tLabel("Nessuna unit disponibile.") }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormatMixins from "../../mixins/FormatsMixins";

export default {
  name: "UnitSelectionPerOperationPanel",
  data() {
    return {
      unitSearchFilter: null,
    };
  },
  props: {
    units: {
      type: Array,
      required: true,
    },
    unitsToAdd: {
      type: Array,
      required: true,
    },
    singleSelection: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mixins: [FormatMixins],
  computed: {
    unitsFiltered() {
      var uaf = JSON.parse(JSON.stringify(this.units));
      if (!this.singleSelection && this.unitsToAdd !== null && this.unitsToAdd.length > 0) {
        var unitVisitIds = [];
        this.unitsToAdd.forEach((unit) => {
          unitVisitIds.push(unit.visitId);
        });
        uaf = uaf.filter((unit) => !unitVisitIds.includes(unit.visitId));
      }
      if (uaf !== null && uaf.length > 0 && this.unitSearchFilter !== null && this.unitSearchFilter !== "") {
        return uaf.filter((unit) => unit.number.toUpperCase().includes(this.unitSearchFilter.toUpperCase()));
      }
      return uaf;
    },
    goodsFiltered() {
      var gaf = JSON.parse(JSON.stringify(this.goods));
      if (this.goodsToAdd !== null && this.goodsToAdd.length > 0) {
        var goodsVisitIds = [];
        this.goodsToAdd.forEach((goo) => {
          goodsVisitIds.push(goo.visitId);
        });
        gaf = gaf.filter((goo) => !goodsVisitIds.includes(goo.visitId));
      }
      if (this.goodsSearchFilter !== null && this.goodsSearchFilter !== "") {
        return gaf.filter(
          (goo) => (goo.code !== null && goo.code.includes(this.goodsSearchFilter)) || (goo.description !== null && goo.description.includes(this.goodsSearchFilter))
        );
      }
      return gaf;
    },
  },
  methods: {
    onUnitClick(unit) {
      if (this.unitsToAdd.length > 0 && this.singleSelection === true) {
        this.removeFromUnitsToAdd(this.unitsToAdd[0]);
      }    
      this.unitsToAdd.push(JSON.parse(JSON.stringify(unit)));      
    },
    removeFromUnitsToAdd(unit) {
      var index = this.unitsToAdd.indexOf(unit);
      if (index > -1) {
        this.unitsToAdd.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
