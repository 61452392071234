<template>
  <div>
    <v-container v-if="operation" fluid style="height: calc(100vh - 88px)" class="grey lighten-2">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-card rounded="0">
            <v-card-text>
              <v-container fluid class="my-0 py-0">
                <v-row align="center">
                  <v-col cols="1">
                    <v-icon size="50" @click="goToStripping">mdi-arrow-left</v-icon>
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <div class="text-subtitle-1">STRIPPING</div>
                    <div class="text-h5 font-weight-bold blue--text">
                      {{ operation.orderReference }} <span class="text-subtitle-1 font-weight-light pl-4">{{ operation.orderExtCode }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- {{ operation }} -->

      <v-row align="center" no-gutters style="height: calc(100vh - 192px)">
        <v-col cols="12" class="fill-height">
          <v-container fluid class="fill-height mx-0 px-0">
            <v-row no-gutters class="fill-height">
              <v-col cols="5">
                <!-- {{ selectedStripped }} -->
                <v-card img="assets/container-icon.png" rounded="0" class="fill-height" style="border: 3px solid grey" ref="unitCard">
                  <v-card-title :class="operation.status === 'E' ? 'green lighten-5' : 'light-blue lighten-5'">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row align="center">
                        <v-col v-if="!operation.unit || operation.unit === null" cols="4" class="my-0 py-0"></v-col>
                        <v-col v-else-if="operation.unit.ata && operation.unit.ata !== null" cols="4" class="text-subtitle-2 text-uppercase text-left my-0 py-0">
                          <v-icon color="secondary">mdi-warehouse</v-icon>
                        </v-col>
                        <v-col v-else cols="4" class="text-subtitle-2 text-uppercase text-left my-0 py-0">
                          {{ operation.unit.eta | formatDateTimeFromDate }} <v-icon color="secondary" class="ml-2">mdi-clock-fast</v-icon>
                        </v-col>
                        <v-col cols="8" class="text-h6 text-uppercase font-weight-medium text-right my-0 py-0">
                          <v-btn class="mr-2" small icon :title="tLabel('Assegna unit')" @click="prepareAssignUnit" v-if="goodsStripped.length === 0"
                            ><v-icon :color="assignUnitColor" @mouseover="assignUnitColor = 'blue darken-3'" @mouseleave="assignUnitColor = null">mdi-pencil</v-icon></v-btn
                          >{{ operation.unit && operation.unit !== null ? operation.unit.number : "UNIT NON ASSEGNATA" }}
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="6" class="text-subtitle-1 text-uppercase text-left my-0 py-0">{{
                          operation.unit && operation.unit !== null ? (operation.unit.position && operation.unit.position !== null ? operation.unit.position : "N/A") : "&nbsp;"
                        }}</v-col>
                        <v-col cols="6" class="text-subtitle-1 text-uppercase text-right my-0 py-0">{{
                          operation.unit && operation.unit !== null ? operation.unit.type : "&nbsp;"
                        }}</v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text style="height: calc(100vh - 300px)" class="scroller">
                    <!-- {{ goodsToStrip }} -->
                    <v-container fluid :key="unitGoodsContainerKey">
                      <v-row align="center">
                        <v-col cols="4" v-for="goo in goodsToStrip" :key="goo.visitId">
                          <OrderOpGoodsOperativeCard
                            :goods="goo"
                            @goodsSelected="onGoodsSelected"
                            :selectable="operation.unit && operation.unit !== null && operation.unit.locked === false"
                            removable
                            @goodsRemoving="onGoodsRemoving"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="primary"
                    v-if="!operation.unit || operation.unit === null"
                    @click="prepareCreateGoods"
                    :title="tLabel('Aggiungi merce all\'operazione di stripping')"
                  >
                    <v-icon size="60">mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="blue darken-4"
                    v-if="operation.unit && operation.unit !== null && operation.unit.locked === true"
                    :disabled="!operation.unit.ata || operation.unit.ata === null"
                    @click="openUnit"
                    :title="tLabel('Apri unit')"
                  >
                    <v-icon size="50" color="white">mdi-lock</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="grey lighten-1"
                    v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && operation.status !== 'E'"
                    @click="undoOpenUnit"
                    :title="tLabel('Annulla apertura unit')"
                  >
                    <v-icon size="50">mdi-lock-open-variant</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="100"
                    width="100"
                    class="floatCenter"
                    elevation="9"
                    color="green"
                    v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && operation.status === 'E'"                   
                    @click="undoFinishStripping"
                    :title="tLabel('Annulla termina operazione di stripping')"
                  >
                    <v-icon size="50" color="white">mdi-undo-variant</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="60"
                    width="60"
                    class="floatTop"
                    elevation="9"
                    color="success"
                    v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && operation.status !== 'E'"
                    @click="finishStrippingDialogVisible = true"
                    :title="tLabel('Termina operazione di stripping')"
                  >
                    <v-icon size="40">mdi-check</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    height="60"
                    width="60"
                    class="floatTopRight"
                    elevation="9"
                    color="primary"
                    v-if="operation.unit && operation.unit !== null && operation.status !== 'E'"
                    @click="prepareCreateGoods"
                    :title="tLabel('Aggiungi merce all\'operazione di stripping')"
                  >
                    <v-icon size="40">mdi-plus</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-container fluid class="fill-height pa-0 ma-0">
                  <v-row class="fill-height">
                    <v-col cols="12" align-self="center" class="text-center">
                      <v-btn
                        fab
                        outlined
                        height="140"
                        width="140"
                        elevation="6"
                        color="secondary"
                        v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && selectedToStrip.length > 0"
                        @click="prepareStrip"
                      >
                        <v-icon size="140"> mdi-chevron-right </v-icon>
                      </v-btn>
                      <v-icon v-else color="secondary" size="140" disabled> mdi-chevron-right </v-icon>
                    </v-col>
                    <v-col cols="12" align-self="center" class="text-center">
                      <v-btn
                        fab
                        outlined
                        height="140"
                        width="140"
                        elevation="6"
                        color="secondary"
                        v-if="operation.unit && operation.unit !== null && operation.unit.locked === false && selectedStripped.length > 0"
                        @click="unstripSelected"
                      >
                        <v-icon size="140"> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-icon v-else color="secondary" size="140" disabled> mdi-chevron-left </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="5">
                <!-- {{ selectedToStrip }} -->
                <v-card rounded="0" class="fill-height" ref="goodsCard">
                  <v-card-title class="grey lighten-4">
                    <v-container fluid class="ma-0 pa-0">
                      <v-row align="center">
                        <v-col cols="12" class="text-h6 text-uppercase font-weight-medium text-right my-0 py-0">{{ tLabel("Goods") }}</v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="12" class="text-subtitle-1 text-uppercase text-left my-0 py-0">&nbsp;</v-col>
                      </v-row>
                    </v-container>
                  </v-card-title>
                  <v-card-text style="height: calc(100vh - 300px)" class="scroller">
                    <!-- {{ goodsStripped }} -->
                    <v-container fluid :key="strippedGoodsContainerKey">
                      <v-row align="center">
                        <v-col cols="4" v-for="goo in goodsStripped" :key="goo.visitId">
                          <OrderOpGoodsOperativeCard
                            :goods="goo"
                            :color="operation.status === 'E' ? 'green' : 'blue'"
                            :selectable="operation.status !== 'E'"
                            @goodsSelected="onGoodsSelected"
                            :removable="operation.status !== 'E'"
                            @goodsRemoving="onGoodsRemoving"
                            positionVisible
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createGoodsDialogVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Crea merce") }}</v-card-title>
        <v-card-text>
          <GoodsCreationPerOperationPanel :goodsToCreate="goodsToCreate" :position="operation && operation.unit && operation.unit !== null ? operation.unit.number : null" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="createGoodsDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="createGoods" :disabled="goodsToCreate.length === 0"><v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Crea") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteGoodsDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Elimina merce") }}</v-card-title>
        <v-card-text class="text-h6 py-2" v-if="goodsToDelete !== null">
          Eliminare la merce {{ goodsToDelete.code }}
          {{ goodsToDelete.description && goodsToDelete.description !== null && goodsToDelete.description !== "" ? " - " + goodsToDelete.description : "" }} ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="deleteGoodsDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="deleteGoods"><v-icon>mdi-delete</v-icon>{{ tLabel("Elimina") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="finishStrippingDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Termina stripping") }}</v-card-title>
        <v-card-text class="text-h6 py-2" v-if="operation && operation !== null && operation.unit && operation.unit !== null">
          Terminare l'operazione di stripping dell'ordine {{ operation.orderExtCode }} per la unit {{ operation.unit.number }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="finishStrippingDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="finishStripping"><v-icon>mdi-check</v-icon>{{ tLabel("Ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="unitAssignDialogVisible" max-width="800">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Assegna unit") }}</v-card-title>
        <v-card-text>
          <UnitSelectionPerOperationPanel :units="unitsAvailable" :unitsToAdd="unitsToAssign" singleSelection />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="unitAssignDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="assignUnit" :disabled="unitsToAssign.length === 0"><v-icon>mdi-check</v-icon>{{ tLabel("Assegna") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="stripDialogVisible" max-width="80%">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Strip merce") }}</v-card-title>
        <v-card-text>
          <v-container fluid class="my-2">
            <v-row align="center">
              <v-col cols="5"></v-col>
              <v-col cols="3" class="text-subtitle-2 font-weight-light text-right"> Stessa posizione per tutte le merci </v-col>
              <v-col cols="3" class="text-right">
                <v-text-field v-model="positionAll" dense @input="(val) => (positionAll = val ? val.toUpperCase() : null)"/>
              </v-col>
              <v-col cols="1" class="text-center">
                <v-btn small outlined color="primary" @click="setPositionToAll"><v-icon>mdi-check</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid class="my-2 fill-height scroller" style="max-height: 550px" :key="goodsToStripContainerKey">
            <v-row align="center">
              <v-col cols="12">
                <!-- {{ selectedToStripObjs }} -->
                <div v-for="gts in selectedToStripObjs" :key="gts.visitId" class="mb-6">
                  <!-- {{ gts }} -->
                  <v-card rounded="0" class="mb-1 grey lighten-4">
                    <v-card-text>
                      <v-row align="center" class="text-subtitle-1 font-weight-medium">
                        <v-col cols="1">
                          <v-icon x-large>mdi-dolly</v-icon>
                        </v-col>
                        <v-col cols="2">
                          Codice
                          <div class="blue--text text-h6 font-weight-normal">{{ gts.code && gts.code !== null ? gts.code : "N/A" }}</div>
                        </v-col>
                        <v-col cols="3">
                          Descrizione
                          <div class="font-weight-light">{{ gts.description && gts.description !== null ? gts.description : "N/A" }}</div>
                        </v-col>
                        <v-col cols="2">
                          Peso lordo
                          <div v-if="gts.weight && gts.weight !== null" class="font-weight-light">{{ gts.weight || numberRounded2WithComma }} Kg</div>
                          <div v-else class="font-weight-light">N/A</div>
                        </v-col>
                        <v-col cols="4">
                          Colli
                          <div class="font-weight-light">{{ gts.quantity && gts.quantity !== null ? gts.quantity : "N/A" }}</div>
                        </v-col>
                        <!-- <v-col cols="1" class="text-right">
                          <v-btn fab outlined small color="primary" :title="tLabel('Aggiungi stripping per questa merce.')" @click="addStrippingPreStrip(gts)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card v-for="strip in gts.stripObj" :key="strip.id" rounded="0" class="mb-1 grey lighten-3">
                    <v-card-text>
                      <v-row align="center" class="text-h6">
                        <v-col cols="2" class="text-h5"> STRIPPING </v-col>
                        <v-col cols="2" class="text-h5">
                          <v-icon x-large>mdi-chevron-right</v-icon>
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-text-field background-color="white" :label="tLabel('Position')" v-model="strip.position" @input="(val) => (strip.position = val.target.value ? val.target.value.toUpperCase() : null)" />
                        </v-col>
                        <v-col cols="4" class="text-right">
                          <v-container fluid class="ma-0 pa-0">
                            <v-row align="center">
                              <v-col cols="6" class="text-right">
                                <v-text-field background-color="white" :label="tLabel('Colli')" v-model.number="strip.quantity" :rules="[rules.numeric]" />
                              </v-col>
                              <v-col cols="1" class="text-center">/</v-col>
                              <v-col cols="5" class="text-left">{{ gts.quantity }}</v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                        <!-- <v-col cols="1" class="text-right">
                          <v-btn v-if="gts.stripObj.length > 1" fab outlined small color="primary" :title="tLabel('Rimuovi stripping per questa merce.')" @click="removeStrippingPreStrip(gts, strip)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="stripDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="stripSelected"><v-icon>mdi-check</v-icon>{{ tLabel("Esegui") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatMixins from "../../../mixins/FormatsMixins";
import ToastMixin from "../../../mixins/ToastMixin";
import OrderOpGoodsOperativeCard from "@/components/yard/OrderOpGoodsOperativeCard";
import UnitSelectionPerOperationPanel from "@/components/yard/UnitSelectionPerOperationPanel";
import GoodsCreationPerOperationPanel from "@/components/yard/GoodsCreationPerOperationPanel";

export default {
  name: "StrippingOperation",
  data() {
    return {
      operation: null,
      order: null,
      selectedToStrip: [],
      selectedToStripObjs: [],
      selectedStripped: [],
      unitAssignDialogVisible: false,
      assignUnitColor: null,
      unitsToAssign: [],
      unitsNotExited: [],
      unitsAvailable: [],
      createGoodsDialogVisible: false,
      deleteGoodsDialogVisible: false,
      goodsToCreate: [],
      goodsToDelete: null,
      unitGoodsContainerKey: 0,
      strippedGoodsContainerKey: 0,
      stripDialogVisible: false,
      positionAll: null,
      goodsToStripContainerKey: 0,
      finishStrippingDialogVisible: false,
    };
  },
  props: {
    operationId: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatMixins, ToastMixin],
  components: { OrderOpGoodsOperativeCard, UnitSelectionPerOperationPanel, GoodsCreationPerOperationPanel },
  async mounted() {
    this.operation = await this.$api.yardData.getOperationById(this.operationId);
    // console.log("operation", this.operation, JSON.stringify(this.operation));
  },
  computed: {
    goodsToStrip() {
      if (this.operation !== null && this.operation.goods && this.operation.goods !== null) {
        return this.operation.goods.filter((g) => g.stripped === false);
      }
      return [];
    },
    goodsStripped() {
      if (this.operation !== null && this.operation.goods && this.operation.goods !== null) {
        return this.operation.goods.filter((g) => g.stripped === true);
      }
      return [];
    },
  },
  methods: {
    goToStripping() {
      if (this.attrs) {
        this.attrs.operationFromId = this.operationId;
      }
      this.$router.push({
        name: "Stripping",
        params: {
          attrs: this.attrs,
        },
      });
    },
    onGoodsSelected(gs) {
      // console.log("onGoodsSelected --->", gs);
      if (gs.selected === true) {
        // aggiungere id alla lista giusta tra strip e stripped
        if (this.goodsToStrip && this.goodsToStrip !== null && this.goodsToStrip.length > 0 && this.goodsToStrip.some((g) => g.handlingId === gs.handlingId)) {
          this.selectedToStrip.push(gs.handlingId);
          return;
        }
        if (this.goodsStripped && this.goodsStripped !== null && this.goodsStripped.length > 0 && this.goodsStripped.some((g) => g.handlingId === gs.handlingId)) {
          this.selectedStripped.push(gs.handlingId);
          return;
        }
      } else {
        // rimuovere dalla lista che lo contiene
        if (this.selectedToStrip.length > 0 && this.selectedToStrip.some((gHandlingId) => gHandlingId === gs.handlingId)) {
          var index = this.selectedToStrip.indexOf(gs.handlingId);
          if (index > -1) {
            this.selectedToStrip.splice(index, 1);
          }
          return;
        }
        if (this.selectedStripped.length > 0 && this.selectedStripped.some((gHandlingId) => gHandlingId === gs.handlingId)) {
          var index = this.selectedStripped.indexOf(gs.handlingId);
          if (index > -1) {
            this.selectedStripped.splice(index, 1);
          }
          return;
        }
      }
    },
    async prepareAssignUnit() {
      this.unitsToAssign = [];
      if (this.operation.unit && this.operation.unit !== null) {
        this.unitsToAssign.push(JSON.parse(JSON.stringify(this.operation.unit)));
      }
      this.unitsNotExited = await this.$api.yardData.getUnitsNotExited("stripping");

      if (this.order === null) {
        this.order = await this.$api.yardData.getOrder("stripping", this.operation.orderId);
      }
      if (this.order.operations && this.order.operations !== null && this.order.operations.length > 0) {
        var unitVisitIds = [];
        this.order.operations.forEach((op) => {
          if (op.id !== this.operationId && op.unit && op.unit !== null) {
            unitVisitIds.push(op.unit.visitId);
          }
        });
        console.log("visit ids unit", unitVisitIds);
        if (unitVisitIds.length > 0) {
          this.unitsAvailable = this.unitsNotExited.filter((unit) => !unitVisitIds.includes(unit.visitId));
        } else {
          this.unitsAvailable = JSON.parse(JSON.stringify(this.unitsNotExited));
        }
      } else {
        this.unitsAvailable = JSON.parse(JSON.stringify(this.unitsNotExited));
      }
      this.unitAssignDialogVisible = true;
    },
    async assignUnit() {
      try {
        this.operation = await this.$api.yardData.setUnit(this.operationId, this.unitsToAssign[0]);
        this.showSuccess("Unit assegnata correttamente all'operazione di stripping.");
        this.unitAssignDialogVisible = false;
      } catch (ex) {
        this.showError("Errore assegnazione unit: " + ex.detailMessage);
      }
    },
    onGoodsRemoving(gr) {
      this.goodsToDelete = JSON.parse(JSON.stringify(gr));
      this.deleteGoodsDialogVisible = true;
    },
    async deleteGoods() {
      try {
        this.operation = await this.$api.yardData.removeGoods(this.operationId, this.goodsToDelete.visitId);
        this.showSuccess("Merce eliminata correttamente.");
      } catch (ex) {
        this.showError("Errore eliminazione merce: " + ex.detailMessage);
      } finally {
        this.deleteGoodsDialogVisible = false;
      }
    },
    async prepareCreateGoods() {
      this.goodsToCreate = [];
      this.createGoodsDialogVisible = true;
    },
    async createGoods() {
      try {
        this.operation = await this.$api.yardData.createGoods(this.operationId, this.goodsToCreate);
        this.showSuccess("Merce aggiunta correttamente all'operazione di stripping.");
        this.createGoodsDialogVisible = false;
      } catch (ex) {
        this.showError("Errore aggiunta merce: " + ex.detailMessage);
      }
    },
    prepareStrip() {
      console.log("prepareStrip", JSON.stringify(this.goodsToStrip), JSON.stringify(this.selectedToStrip));
      this.positionAll = null;
      this.selectedToStripObjs = this.goodsToStrip.filter((gts) => this.selectedToStrip.some((sts) => sts === gts.handlingId));
      this.selectedToStripObjs.forEach((sts) => {
        sts.stripObj = [
          {
            id: 1,
            // handlingId: sts.handlingId,
            position: null,
            quantity: sts.quantity,
          },
        ];
      });
      this.stripDialogVisible = true;
    },
    addStrippingPreStrip(goods) {
      console.log("addStrippingPreStrip", goods);
      var totQty = 0;
      goods.stripObj.forEach((so) => {
        totQty += so.quantity;
      });
      goods.stripObj.push({
        id: goods.stripObj.length + 1,
        // handlingId: goods.handlingId,
        position: null,
        quantity: goods.quantity - totQty > 0 ? goods.quantity - totQty : 0,
      });
      this.goodsToStripContainerKey++;
      console.log("selectedToStripObjs", this.selectedToStripObjs);
    },
    removeStrippingPreStrip(goods, stripToRemove) {
      console.log("removeStrippingPreStrip", goods, stripToRemove);
      var index = goods.stripObj.indexOf(stripToRemove);
      if (index > -1) {
        goods.stripObj.splice(index, 1);
        this.goodsToStripContainerKey++;
      }
    },
    setPositionToAll() {
      this.selectedToStripObjs.forEach((sts) => {
        sts.stripObj.forEach((s) => {
          s.position = this.positionAll;
        });
      });
      this.goodsToStripContainerKey++;
    },
    async stripSelected() {
      try {
        console.log("stripSelected selectedToStripObjs", this.selectedToStripObjs);
        // TODO
        var goos = [];
        this.selectedToStripObjs.forEach((sts) => {
          sts.stripObj.forEach((so) => {
            goos.push({
              handlingId: sts.handlingId,
              code: sts.code,
              quantity: so.quantity,
              position: so.position && so.position !== null ? so.position.toUpperCase() : null,
            });
          });
        });
        this.operation = await this.$api.yardData.strip(this.operationId, goos);
        this.selectedToStrip = [];
        // this.unitGoodsContainerKey++;
        this.stripDialogVisible = false;
      } catch (ex) {
        this.showError("Errore stripping merce: " + ex.detailMessage);
      }
    },
    async unstripSelected() {
      try {
        // TODO
        var selectedStrippedObjs = this.goodsStripped.filter((gts) => this.selectedStripped.some((sts) => sts === gts.handlingId));
        this.operation = await this.$api.yardData.unstrip(this.operationId, selectedStrippedObjs);
        // selectedStrippedObjs.forEach((ss) => {
        //   ss.stripped = false;
        // });
        this.selectedStripped = [];
      } catch (ex) {
        this.showError("Errore undo stripping merce: " + ex.detailMessage);
      }
    },
    async openUnit() {
      try {
        this.operation = await this.$api.yardData.openUnit(this.operationId);
        this.selectedStripped = [];
        this.selectedToStrip = [];
        this.unitGoodsContainerKey++;
      } catch (ex) {
        this.showError("Errore apertura unit: " + ex.detailMessage);
      }
    },
    async undoOpenUnit() {
      try {
        this.operation = await this.$api.yardData.undoOpenUnit(this.operationId);
        this.selectedStripped = [];
        this.selectedToStrip = [];
        this.unitGoodsContainerKey++;
      } catch (ex) {
        this.showError("Errore annullamento apertura unit: " + ex.detailMessage);
      }
    },
    async finishStripping() {
      console.log("finishStripping", this.operationId);
      try {
        this.operation = await this.$api.yardData.finishStripping(this.operationId);
        this.showSuccess("Operazione di stripping terminata correttamente.");
        this.selectedStripped = [];
        this.selectedToStrip = [];
        this.strippedGoodsContainerKey++;
        this.finishStrippingDialogVisible = false;
      } catch (ex) {
        this.showError("Errore terminazione operazione di stripping: " + ex.detailMessage);
      }
    },
    async undoFinishStripping() {
      console.log("undoFinishStripping", this.operationId);
      try {
        this.operation = await this.$api.yardData.undoFinishStripping(this.operationId);
        this.showSuccess("Annullamento terminazione operazione di stripping eseguito correttamente.");
        this.selectedStripped = [];
        this.selectedToStrip = [];
        this.strippedGoodsContainerKey++;
      } catch (ex) {
        this.showError("Errore annullamento terminazione operazione di stripping: " + ex.detailMessage);
      }
    },
  },
};
</script>

<style scoped>
.floatCenter {
  position: absolute;
  top: 82%;
  left: 5%;
  opacity: 0.6;
}
.floatCenter:hover {
  opacity: 1;
}

.floatTop {
  position: absolute;
  top: 72%;
  left: 7.5%;
  opacity: 0.6;
}
.floatTop:hover {
  opacity: 1;
}

.floatTopRight {
  position: absolute;
  top: 76%;
  left: 18%;
  opacity: 0.6;
}
.floatTopRight:hover {
  opacity: 1;
}

.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}
</style>
